import styled from "styled-components";

const HeaderWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 5;
    height: 0px;

    background-color: transparent;
`

const Header = styled.div`
    height: 0px;
    width: 90%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    background-color: transparent;
`

const HeadItem = styled.div`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 1.5em;
    color: gray;

    transition: 0.1s ease all;

    &:active {
        transform: scale(1.1) rotate(3deg);
    }
`

const Menu = styled.div`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 1.7em;
    color: #454545;

    transition: 0.1s ease all;

    position: absolute;
    left: 30px;

    &:active {
        transform: scale(1.1) rotate(3deg);
    }
`

const Support = styled.a`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 1.7em;
    color: #454545;

    transition: 0.1s ease all;

    position: absolute;
    right: 30px;

    &:active {
        transform: scale(1.1) rotate(3deg);
    }
`

export const Logo = styled.img`
    width: 100px;
    padding: 5px;

    position: absolute;
    left: 00;
    top: 0;

    background-color: #FF3130;

    z-index: 2;
`

export const Bar = styled.div`
    width: 100%;
    height: 5px;

    position: absolute;
    left: 0;
    top: 0;

    background-color: #FF3130;

    z-index: 2;
`

export const Options = styled.div`
width: 60%;
height: 40px;

position: absolute;
right: 0;
top: 5px;

background-color: white;

border: 1px solid #FF3130;
border-right: 0px;

display: flex;
align-items: center;
justify-content: space-around;

z-index: 2;
`

export const LabelOption = styled.span`
    color: #FF3130;

    height: 100%;
    width: ${props => props.width ? props.width : 'auto'};

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.8rem;

    border-right: 2px solid #FF3130;

    text-align: center

    display: flex;
    flex-direction: column;
`

export {
    HeaderWrapper,
    Header,
    HeadItem,
    Menu, Support
};