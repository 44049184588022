import { useQuery } from 'react-query'
import React, { useState, useEffect } from "react";
import { AnimatePresence } from 'framer-motion';

import { Link, useParams } from "react-router-dom";
import client from "../../../assets/service/client";
import FriendsModal from "./modal/FriendsModal";

import { BsSpotify } from 'react-icons/bs';

import { RiInstagramFill } from 'react-icons/ri'

import GoogleMapReact from 'google-map-react';

import {
    Container,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,
    Button,
    MapContainer,
    TextContainer,
    Text,
    Scroll,
    SpotifyWrapper,
    FirstWrapper,
    Logo,
    Overlay,
    Area
} from './styles';
import Loader from '../../../assets/components/loader/Loader';

const EventSpec = () => {
    const [showModal, setShowModal] = useState(false)
    let { alias } = useParams();

    const getEventSpecs = async () => {
        return await client.get(`event/alias/${alias}`)
        .then(res => {            
            if (res.data.event.pixelId) {
                window.fbq('init', `${res.data.event.pixelId}`);
                window.fbq('trackSingle', `${res.data.event.pixelId}`, 'PageView')
                window.fbq('trackSingle', process.env.REACT_APP_PIXEL_ID, 'PageView');
            }

            console.log(res.data.event)
            return res.data.event;
        })
    }

    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
          position: {lat: parseFloat(specs.location.lat), lng: parseFloat(specs.location.lon)},
          map,
          title: 'Hello World!'
        });
    }

    const parseDate = (date) => {
        let d = new window.Date(date);
        return d.toLocaleString("pt-br", {
            month: "short", day:"numeric", weekday: "short"
        });
    }

    const { isLoading, isError, data: specs, error } = useQuery(['eventSpec', alias], getEventSpecs, { keepPreviousData: false });

    return (
        <>
        <svg width="0" height="0">
            <radialGradient id="insta-gradient" r="150%" cx="30%" cy="107%">
                <stop stopColor="#fdf497" offset="0" />
                <stop stopColor="#fdf497" offset="0.05" />
                <stop stopColor="#fd5949" offset="0.45" />
                <stop stopColor="#d6249f" offset="0.6" />
                <stop stopColor="#285AEB" offset="0.9" />
            </radialGradient>
        </svg>
        {
        isLoading ? <Loader /> :
        isError ? 
        <>
        <div style={{
            height: '200px',
            width: '80%',
            maxWidth: "350px",
            backgroundImage: "url('https://i.kym-cdn.com/photos/images/original/001/042/619/4ea.jpg')",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "15px"
        }} />
        <div style={{height: "2em"}} />
        <span style={{fontSize: "1.2em", color: "gray"}}>Erro ao encontrar o evento</span>
        <div style={{height: "2em"}} />
        </>
        :
        showModal ? <FriendsModal key="friends-modal" closeModal={() => setShowModal(false)} eventId={specs.id} /> :
        specs ? 
        (
            <Container key="event-spec" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
                {/* { specs.listAvailable && <ToggleFriendList onClick={() => setShowModal(true)}/> } */}
                <Scroll className="no-scroll">
                <FirstWrapper>
                    <video 
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            display: "block",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }} 
                        src="https://gandaya.b-cdn.net/all-jeans-v2.mp4" autoPlay 
                        loop 
                        muted 
                        playsInline
                    />
                    <Overlay />
                    <Area>
                        <Logo src="/logo/main.png"/>
                        <Date>{parseDate(specs.date).replace(".", '')} | 20:00</Date>
                        <span style={{color: "white", marginTop:"10px", fontWeight:"800"}}>OPEN BAR</span>
                    </Area>
                </FirstWrapper>
                <TextContainer>
                    <span 
                        style={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            margin: "10px 0 20px 0"
                        }}
                    >MANIFESTO</span>
                    <Text>Atemporal <br></br>
                        O jeans é <br></br>
                        protagonista <br></br>
                        nas pistas de <br></br>
                        dança das <br></br>
                        últimas <br></br>
                        décadas <br></br>
                        Do movimento <br></br>
                        house ao <br></br>
                        disco <br></br>
                        Da cultura <br></br>
                        pop ao hip hop <br></br>
                        Do tempero <br></br>
                        latino <br></br>
                        brasileiro <br></br>
                        Estamos <br></br>
                        engajados e <br></br>
                        vivos por <br></br>
                        todos os <br></br>
                        gêneros!
                    </Text>
                    <SpotifyWrapper>
                    <iframe style={{borderRadius:"0px"}} src="https://open.spotify.com/embed/playlist/2st7Us4aOwxjkpot5y2TdT?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    </SpotifyWrapper>
                    {
                        specs.location && 
                        <MapContainer>
                            <GoogleMapReact
                            bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS_API_KEY }} 
                            defaultCenter={{lat: parseFloat(specs.location.lat), lng: parseFloat(specs.location.lon)}} 
                            defaultZoom={15} 
                            onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
                        />
                        </MapContainer>
                    }
                </TextContainer>
                <Button to='tickets'>Comprar Ingresso</Button>
                </Scroll>
            </Container>
        )
        :
        <></>
        }
        </>
    )
}

export default EventSpec;