import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

export const Page = styled.div`
    top: 0;
    bottom: 0;
    height: 100%;

    display: flex;
    flex-direction: column;

    color: white;
    font-weight: 700;

    overflow: scroll;
`

export const Logo = styled.img`
    width: 100px;
    padding: 5px;

    position: absolute;
    left: 00;
    top: 0;

    background-color: #FF3130;

    z-index: 2;
`

export const Bar = styled.div`
    width: 100%;
    height: 5px;

    position: absolute;
    left: 0;
    top: 0;

    background-color: #FF3130;

    z-index: 2;
`

export const Options = styled.div`
width: 60%;
height: 40px;

position: absolute;
right: 0;
top: 5px;

background-color: white;

border: 1px solid #FF3130;
border-right: 0px;

display: flex;
align-items: center;
justify-content: space-around;

z-index: 2;
`

export const LabelOption = styled.span`
    color: #FF3130;

    height: 100%;
    width: ${props => props.width ? props.width : 'auto'};

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.8rem;

    border-right: 2px solid #FF3130;
`

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.55);
    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const OverlayInfo = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    background-image: '/image00001.png';

    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const Label = styled.span`
    background-color: white;
    border: 1px solid #FF3130;
    color: #FF3130;
    height: 40px;

    display: flex;
    align-items: center;

    font-size: 0.8rem;
    font-weight: 300;

    width: 75%;

    padding-left: 15px;
`

export const BgManifesto = styled.div`
    margin-top: -100px;
    width: 100%;

    background-color: #FF3130;

    /* border-radius: 40px; */

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 10px;

    z-index: 2;
`

export const InnerImageManifesto = styled.img`
    position: relative;
    margin-top: -75px;
    margin-bottom: 30px;
    width: 60%;

    border: 4px solid black;
`

export const ManifestoDiv = styled.div`
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;

    /* border-radius: 20px; */

    padding: 20px 0;

    margin-bottom: 20px;
`

export const ManifestoTitle = styled.span`
    font-size: 1.5rem;
    color: black;
    margin-bottom: 20px;
`

export const ManifestoText = styled.span`
    font-size: 0.75rem;
    color: black;
    font-weight: 300;

    padding: 0 20px;
`

export const CoverImageDiv = styled.div`
    position: relative;
    height: 100vh;
    width: 100vw;

    z-index: -2;
`

export const SpotifyDiv = styled.div`
    position: relative;
    width: 100vw;
    height: 400px;

    margin-top: -40px;

    /* transform: translate(0, -150px); */

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 0;
`

export const SpotifyInnerDiv = styled.div`
    width: 90%;
    height: 100%;

    margin: 100px 0;

    /* transform: translate(0, -150px); */

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 10;
`

export const BgAddress = styled.div`

    background-color: #FF3130;

    /* border-radius: 40px; */

    margin-top: -40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 1;
`

export const BgVideo = styled.div`
    height: 101vh;
    width: 100vw;

    margin-top: -40px;

    z-index: -1;
`

export const Button = styled(Link)`
    width: 100%;
    padding: 15px 0 15px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: -10px;

    font-family: Malstrom;

    color: white;
    background-color: #FF3130;
    border: 0px;

    text-decoration: none;
    z-index: 2;
`

export const Info = styled.div`
    // width: 100%;
    // padding: 15px 0 15px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    padding: 10px 0;

    text-align: center;

    // margin-top: -10px;

    // font-family: Malstrom;

    // color: white;
    // background-color: #FF3130;
    // border: 0px;

    // text-decoration: none;
    // z-index: 2;
`