import { useEffect, useRef } from "react";
import { useState } from "react";
import {
    Page,
    Logo,
    Overlay,
    InnerImageManifesto,
    Bar,
    Options,
    LabelOption,
    BgManifesto,
    CoverImageDiv,
    ManifestoDiv,
    ManifestoTitle,
    ManifestoText,
    SpotifyDiv,
    SpotifyInnerDiv,
    BgAddress,
    BgVideo,
    Button,
    OverlayInfo,
    Info
} from "./styles";

import { FiMenu } from 'react-icons/fi'

import GoogleMapReact from 'google-map-react';

const Home = (props) => {
    const [specs, setSpecs] = useState({
        location: {
            lat: -23.528076929117386,
            lon: -46.66802334418007
        }
    });
    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
          position: {lat: parseFloat(specs.location.lat), lng: parseFloat(specs.location.lon)},
          map,
          title: 'Hello World!'
        });
    }

    return (
    <Page>
        <CoverImageDiv>
            <img 
                style={{
                    width: "100vw",
                    height: "100vh",
                    objectFit: "cover",
                    zZindex: "-1"
                }} 
                src="/f1.jpeg" 
                alt="Woman dancing"
            />
            <Overlay />
            <img 
                src="/image00001.png" 
                style={{
                    position: "absolute",
                    width: "100%",
                    maxWidth: "300px",
                    top: "0",
                    bottom: "0",
                    right: "0",
                    margin: "auto 0"
                }}
                alt="Info"
                
            />
        </CoverImageDiv>
        <BgManifesto>
            <InnerImageManifesto src="/f2.jpeg"/>
            <ManifestoDiv>
                <ManifestoTitle>MANIFESTO</ManifestoTitle>
                <ManifestoText>
                    Atemporal O jeans é <br></br>
                    protagonista nas pistas de <br></br>
                    dança das últimas décadas <br></br>
                    Do movimento house ao disco <br></br>
                    Da cultura pop ao hip hop <br></br>
                    Do tempero latino <br></br>
                    brasileiro <br></br>
                    Estamos engajados e vivos <br></br>
                    por todos os gêneros!
                </ManifestoText>
            </ManifestoDiv>
        </BgManifesto>
        <SpotifyDiv>
            <video 
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                }} 
                src="https://gandaya.b-cdn.net/all-jeans-v2.mp4" 
                autoPlay 
                loop 
                muted 
                playsInline
            />
            <Overlay />
            <SpotifyInnerDiv>
                <iframe 
                    title="spotify"
                    style={{
                        borderRadius:"0px",
                    }} 
                    src="https://open.spotify.com/embed/playlist/2st7Us4aOwxjkpot5y2TdT?utm_source=generator&theme=0" 
                    width="100%" 
                    height="152" 
                    frameBorder="0" 
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy">
                </iframe>
            </SpotifyInnerDiv>
        </SpotifyDiv>
        <BgAddress>
        { props.info ? 
        <Info>
            <span>ABERTURA DE VENDAS 02/08 - 12h00</span>
            <span>MASC 290</span>
            <span>FEM 240</span>
        </Info> : 
        <Button to="/events/all-jeans/tickets">COMPRAR INGRESSO</Button> 
        }
        </BgAddress>
        <BgVideo>
            <video 
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }} 
                src="https://gandaya.b-cdn.net/all-jeans-v3.mp4" 
                autoPlay 
                loop 
                muted 
                playsInline
            />
        </BgVideo>
        
    </Page>
    )
}

export default Home;