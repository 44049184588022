import { useEffect, useRef } from "react";
import { useState } from "react";
import {
    Page,
    Logo,
    CTA,
    Area,
    Overlay,
    Label,
    Bar,
    Options,
    LabelOption
} from "./styles";

import { BsArrowRight } from 'react-icons/bs'

const PreHome = () => {
    return (
    <Page>
        <video style={{
            position: "absolute",
            top: "0",
            left: "0",
            display: "block",
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
        }} src="https://gandaya.b-cdn.net/all-jeans-trim.mp4" autoPlay loop muted playsInline></video>
        <Overlay />
        <Area>
            <Label to="/all-jeans">Ingressos e informações</Label>
            <CTA to="/all-jeans">
                <BsArrowRight
                    color="white"
                    size="1.5rem"
                />
            </CTA>
        </Area>
    </Page>
    )
}

export default PreHome;