import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

const Page = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-weight: 700;
`

const Logo = styled.img`
    width: 100px;
    padding: 5px;

    position: absolute;
    left: 00;
    top: 0;

    background-color: #FF3130;

    z-index: 2;
`

const Bar = styled.div`
width: 100%;
height: 5px;

position: absolute;
left: 0;
top: 0;

background-color: #FF3130;
`

const Options = styled.div`
width: 60%;
height: 40px;

position: absolute;
right: 0;
top: 5px;

background-color: white;

border: 1px solid #FF3130;

display: flex;
align-items: center;
justify-content: space-around;
`

const LabelOption = styled.span`
    color: #FF3130;

    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.8rem;

    border-right: 2px solid #FF3130;
`

const CTA = styled(Link)`
    height: 40px;
    width: 40px;
    background-color: transparent;
    font-family: Malstrom;
    color: white;
    font-weight: 700;
    background-color: #FF3130;
    text-align: center;
    border: 1px solid #FF3130;

    /* @media (max-height: 900px) {
        font-size: 0.9rem;
    }

    @media (min-height: 901px) {
        font-size: 1.2rem; 
    } */

    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
`

const Area = styled.div`
    z-index: 2;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

    width: 100%;
    height: 60%;
`

const Overlay = styled.div`
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.55);
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
`

const Label = styled(Link)`
    background-color: white;
    border: 1px solid #FF3130;
    color: #FF3130;
    height: 40px;

    display: flex;
    align-items: center;

    font-size: 0.8rem;
    font-weight: 300;

    width: 75%;

    padding-left: 15px;

    text-decoration: none;
`

export {
    Page,
    Logo,
    CTA,
    Area,
    Overlay,
    Label,
    Bar,
    Options,
    LabelOption
}