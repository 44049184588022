import { 
    Header, 
    HeaderWrapper, 
    HeadItem, 
    Menu, 
    Support, 
    Logo,
    Bar,
    Options,
    LabelOption, 
} from "./styles";
import { HiMenuAlt2 } from 'react-icons/hi'
import { BiSupport } from 'react-icons/bi'

import { FiMenu } from 'react-icons/fi'

const Head = (props) => {
 return (
    <HeaderWrapper>
        <Logo src="/logo/main.png"/>
            <Bar/>
            <Options>
                <LabelOption width="30%">
                    <span>11/08</span>
                    <span style={{
                        fontSize: "0.75em",
                        fontWeight: "900",
                    }}>22h</span>
                </LabelOption>
                <LabelOption width="50%">OPEN BAR</LabelOption>
                <LabelOption width="20%">
                    <FiMenu
                        color="#FF3130"
                        size={30}
                        onClick={() => props.onMenuClick()}
                    />
                </LabelOption>
            </Options>
    </HeaderWrapper>
 )
}

export default Head;

{/* <Header>
    <Menu onClick={() => props.onMenuClick()}><HiMenuAlt2 /></Menu>
    <HeadItem />
    <Support href="https://wa.me/+5511995093002" target="_blank"><BiSupport style={{fontSize: "0.75em"}} /></Support>
</Header> */}